<template>
  <div>
    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      :persistent="isMakingRequest"
      max-width="660"
      scrollable
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <span>
            Google Trends
          </span>

          <div class="d-flex align-center">
            <v-btn
              v-if="formData.keywords.length > 1"
              text
              class="mr-3"
              color="white"
              @click="resetForm"
            >
              <v-icon left>
                undo
              </v-icon>

              Reset
            </v-btn>

            <v-btn
              depressed
              class="primary--text"
              @click="addAnotherQuery"
              :disabled="formData.keywords.length >= 5"
            >
              <v-icon left>
                add
              </v-icon>

              Add Keyword
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- Show the inputs -->
          <v-row
            v-for="(item, index) in formData.keywords"
            :key="item.symbol"
          >
            <!-- Show the keyword input -->
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="item.value"
                :items="item.options"
                label="Keyword"
                item-text="text"
                item-value="query"
                return-object
                hide-details
                outlined
                :loading="item.isMakingRequest"
                eager
                no-filter
                clearable
                @update:search-input="(query) => doSearchItems(item, query)"
              >
                <!-- Show loader while the data is loading -->
                <template v-slot:no-data>
                  <div v-if="item.isMakingRequest" class="text-center">
                    <v-progress-circular
                      :width="3"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <div v-else class="text-center py-2">
                    No results found.
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Show the location input -->
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="formData.locations[index]"
                :items="locations"
                label="Location"
                item-text="name"
                item-value="id"
                return-object
                hide-details
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- Show a divider if there are more than one keywords -->
          <v-divider
            v-if="formData.keywords.length > 1"
            class="my-6"
          />

          <!-- Show the inputs for category and time range -->
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="formData.category"
                :items="categories"
                label="Category"
                item-text="name"
                item-value="id"
                return-object
                hide-details
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <date-range-selector
                :max="dayjs().format('YYYY-MM-DD')"
                :min="dayjs().subtract(1, 'year').format('YYYY-MM-DD')"
                :value="formData.dateRange"
                :default-date-range="1"
                :max-date-range="365"
                @input="handleDateRangeValue"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest || availableModuleUsage <= 0"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, maxLength } from "vuelidate/lib/validators"
import dataHelper from "@/helpers/dataHelper"

// Import children components
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// Define the initial keyword data
const initialKeyword = () => ({
  symbol: Symbol(),
  value: null,
  isMakingRequest: false,
  options: []
})

// Define initial form data
const initialFormData = () => ({
  title: "",
  category: 0,
  keywords: [initialKeyword()],
  locations: [null],
  dateRange: null,
  startDate: null,
  endDate: null
})

// Export the SFC
export default {
  name: "GoogleTrendsForm",

  // Register children components
  components: {
    DateRangeSelector
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,

    formData: initialFormData(),

    locations: [],
    categories: [],

    // Regarding autocomplete integration
    _timerId: null,
    abortControllers: []
  }),

  // Define local computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("google-trends")
    },
  },

  // Define form validations
  validations() {
    return {
      formData: {
        startDate: {
          required,
          minimum: (v) => dayjs().diff(dayjs(v), 'day') <= dayjs().diff(dayjs().subtract(1, "year").format("YYYY-MM-DD"), 'day')
        },
        endDate: {
          required,
          minimum: (v) => dayjs(v).diff(this.formData.startDate, 'day') >= 1
        },
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Reset the form data values
     *
     * @returns {void}
     */
    resetForm() {
      this.formData = initialFormData()
    },

    /**
     * Push an entry into both inputs
     *
     * @returns {void}
     */
    addAnotherQuery() {
      // If there are 5 items already
      if (this.formData.keywords.length >= 5) {
        // Stop further execution
        return
      }

      // Push empty values
      this.formData.keywords.push(initialKeyword())
      this.formData.locations.push(null)
    },

    /**
     * Copy the value from child to current state
     *
     * @param {null|Array} value
     */
    async handleDateRangeValue(value) {
      // Copy the value to local value
      this.formData.dateRange = value

      // Check if it's an array
      if (value !== null) {
        // Also update the filter values
        this.formData.startDate = value[0] || null
        this.formData.endDate = value[1] || null

        // Validate the inputs
        await this.$v.formData.startDate.$touch()
        await this.$v.formData.endDate.$touch()

        // If it is invalid
        if (this.$v.formData.startDate.$anyError || this.$v.formData.endDate.$anyError) {
          // Show a snackbar
          this.$store.dispatch("toasts/add", {
            text: "Please enter a valid date range",
          })
        }
      }
    },

    /**
     * Make the API request to populate the autocomplete options
     *
     * @param {Object} keyword
     * @param {String} query
     */
    doSearchItems(keyword, query) {
      // If the query is empty or less than 2 characters
      if (!query || query.length < 2) {
        // Stop further execution
        return
      }

      // If the query value is same as selected value
      if (query === keyword.value?.text) {
        // Stop further execution
        return
      }

      // Cancel all pending calls
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // Delay new call by 500ms
      this._timerId = setTimeout(async () => {
        // If input is empty, don't continue
        if (!query) {
          // Reset the search results
          keyword.options = []

          return
        }

        // Cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()
        }

        // make network requests in vuex store
        const id = Symbol()
        const controller = new AbortController()

        // push this controller to the list
        this.abortControllers.push({ id, controller, query })

        // show the loader while request is being made
        keyword.isMakingRequest = true

        try {
          const response = await axios({
            url: "/api/google-trends/autocomplete/",
            signal: controller.signal,
            method: "POST",
            data: { query }
          })

          // Update the array
          keyword.options = response.data
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          keyword.isMakingRequest = false

          // remove the controller from cancellable array
          const index = this.abortControllers.findIndex((item) => item.id === id)
          this.abortControllers.splice(index, 1)
        }
      }, 500)
    },

    /**
     * Handle the form submission
     *
     * @return {void}
     */
    async handleSubmit() {
      // If we're already making a request
      if (this.isMakingRequest) {
        return
      }

      // Validate the form
      await this.$v.$touch()

    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  async created() {
    // Fetch and store categories and locations
    this.locations = await dataHelper.loadData("google-trends-locations.json")
    this.categories = await dataHelper.loadData("google-trends-categories.json")
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unload the json from the store
    dataHelper.unloadData("google-trends-locations.json")
    dataHelper.unloadData("google-trends-categories.json")
  }
}
</script>